import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../actions/auth'; // Define this action in your Redux setup
import { clearError } from '../actions/general';
import { Error } from './Error';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
       dispatch(clearError());
      await dispatch(forgotPassword(email)); // Trigger your password reset action
       setMessage('Password reset link sent to your email.');
    } catch (error) {
       setMessage('Error sending password reset email.');
    }
  };

  return (

    <section className='form_section '>
    <div className='container mx-auto'>
        <div className='forgot-pass  gap-5'>
            <div className='login_form  p-6 bg-mybluecolor text-center'>
    <div className="mx-auto w-full md:w-1/3 text-left">
       <Error />
      <form onSubmit={handleSubmit}>
    <h3 className='text-white uppercase font-primary text-xl font-semibold mb-10'>Forgot Password</h3>
        <label className='text-white font-primary text-base font-semibold mb-3 block'>Email Address:</label>
        <input
                 className="p-4 placeholder-black text-black relative bg-white text-sm
                                    border-b-2 border-solid w-full mb-4 font-normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address"
          required
        />
        <button className='bg-myorangeolor text-sm font-primary text-white px-6 py-2 border-2 border-myorangeolor
                                    duration-300 hover:bg-transparent' type="submit">Send Reset Link</button>
      </form>
      {message && <p className='bg-green-600 text-white p-3 mb-5 mt-5 rounded'>{message}</p>}
    </div>
    </div>
    </div></div></section>
  );
}

export default ForgotPassword;