import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { usePopup } from '../context/popup';


function MenuLinks(e) {
  const [dropdown,setDropdown] = useState('');
  const { categories,isLoading } = useSelector(( state )=> state.categories);
  const { isOpen, setIsOpen } = usePopup();
  if(isLoading){ return 'Loading'; }
  if(!isLoading && !categories.length){ return 'No Category'; }
 
  const handleDropDownClick = () => {
    setDropdown('displayNone');
  }
 
  const handleDropDownHover = () => {

    setDropdown('');
  }
// Add custom links to the categories array
const additionalLinks = [
  { _id: 'vapes', title: 'Vapes', showInMenu: 1,parent_category: true },
  { _id: 'drinks', title: 'Drinks', showInMenu: 1,parent_category: true }
];

// Combine and sort the categories alphabetically by title
const combinedCategories = [...categories, ...additionalLinks].sort((a, b) => 
  a.title.localeCompare(b.title)
);
  const nav = combinedCategories.map((category)=>( 
    category.showInMenu == 1 && (
      <li key={category._id} className={`${e.myMargin}`}>
  <Link to={(category?.parent_category)? category._id :`/category/${category._id}`} onClick={handleDropDownClick} className='text-white font-primary text-base font-normal uppercase'>{category.title}</Link>
  </li>
    )
    ))
  
  return(
    <ul className={`flex ${e.set} flex-row justify-start gap-4 relative`}>
    
        <li className={`${e.myMargin}`}>
          <a href="/" className='text-white font-primary text-base font-normal uppercase'>Home</a>
        </li>
        
        <li onMouseOver={handleDropDownHover} className={`${e.myMargin} dropdown text-left md`}>
          <a href="#0" className='text-white font-primary text-base font-normal uppercase'>All Categories</a>
          <ul  className={` ${dropdown} dropdown-menu md:absolute md:left-[20px] left-0 md:top-[57px] top-0 relative md:overflow-y-hidden  md:hidden md:w-fit w-full  overflow-hidden overflow-y-scroll visible bg-myorangeolor`}>
            {nav}
            {/* <li className={`${e.myMargin}`}>
              <Link to={'/vapes'} className='text-white font-primary text-base font-normal uppercase'>Vapes</Link>
            </li>
            <li className={`${e.myMargin}`}>
              <Link to={'/drinks'} className='text-white font-primary text-base font-normal uppercase'>Drinks</Link>
            </li> */}
          </ul>
        </li>
        {/* <li className={`${e.myMargin}`}>
          <Link to={'/vapes'} className='text-white font-primary text-base font-normal uppercase'>Vapes</Link>
        </li>
        <li className={`${e.myMargin}`}>
          <Link to={'/drinks'} className='text-white font-primary text-base font-normal uppercase'>Drinks</Link>
        </li> */}
        <li className={`${e.myMargin}`}>
          <Link to={'/contact-us'} className='text-white font-primary text-base font-normal uppercase'>Contact Us</Link>
        </li>   
        <li className={`${e.myMargin}`}>
          <button onClick={() => setIsOpen(true)} className='text-white font-primary text-base font-normal uppercase'>Monthly Flyer</button>
        </li>       
        { !e.user? (
          <>
          <li className={`${e.myMargin} onlyWeb`}>
          <Link to={'/login'} className='text-white font-primary text-base font-normal uppercase'>Login</Link>
        </li>
        <li className={`${e.myMargin} onlyWeb`}>
          <Link to={'/signup'} className='text-white font-primary text-base font-normal uppercase'>Sign up</Link>
        </li>
          </>
        ) : (
          <li className={`${e.myMargin} onlyWeb`}>
          <Link to={'/my-orders'} className='text-white font-primary text-base font-normal uppercase'>My Account</Link>
        </li>
        ) }
        
        
        
       
       
    </ul>
  );
}

export default MenuLinks