import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCaretDown,faCaretUp } from '@fortawesome/free-solid-svg-icons'

export const CategoryTab = () => {
    const { categories,isCatLoading } = useSelector(( state )=> state.categories);
    const [catList,setCatList] = useState(false);

    // if(isCatLoading){ return 'Loading'; }
    // const nav = categories.map((category)=>(  
    //     <li key={category._id}>
    //      <Link to={`/category/${category._id}`} className='p-4 font-primary bg-mybluecolor text-white text-sm block duration-300
    //      border-b-2 border-white hover:bg-myorangeolor'>{category.title}</Link> 
    //      </li> ));

            //  const { categories } = useSelector(( state )=> state.categories);
             // Add custom links to the categories array
             const additionalLinks = [
               { _id: '/vapes', title: 'Vapes', showInSideBar: 1,parent_category: true },
               { _id: '/drinks', title: 'Drinks', showInSideBar: 1,parent_category: true }
             ];
             
             // Combine and sort the categories alphabetically by title
             const combinedCategories = [...categories, ...additionalLinks].sort((a, b) => 
               a.title.localeCompare(b.title)
             );    
                 const nav = combinedCategories.map((category)=>
                     category.showInSideBar == 1 && (
                       <li key={category._id}>
                         <Link 
                           to={(category?.parent_category == true)? category._id :`/category/${category._id}`} 
                           className='p-4 font-primary bg-mybluecolor text-white uppercase text-sm block duration-300 border-b-2 border-white hover:bg-myorangeolor'>
                           {category.title}
                         </Link>
                       </li>
                     ) 
                   );
     
  return (
    <div className='cat_tabs md:basis-1/5'>
    <h3 className='text-white font-primary p-4 bg-myorangeolor block relative'>
        <button className='block w-full text-left' onClick={() => setCatList(!catList)}>
            Shop by Categories
            <div className='icons_div md:hidden block'>
                {
                    catList ?
                    <FontAwesomeIcon icon={faCaretDown} className="absolute right-3 top-5"/>
                    :
                    <FontAwesomeIcon icon={faCaretUp} className="absolute right-3 top-5"/>
                }
            </div>
        </button>
    </h3>
    
    <ul className={`bg-mybluecolor ${catList  ? 'block' :'md:block hidden' }`}>
        {nav}                            
    </ul>
</div>
  )
}
