import React, { useState,useEffect } from 'react'

import { useDispatch,useSelector } from "react-redux";
import { useParams,useLocation,useHistory } from "react-router-dom";
import { getProductsByCategory } from '../actions/products';
import { getCategory } from '../actions/categories';
import ProductSection from './productPage/ProductSection.js';
import { Link } from 'react-router-dom';
import { CategoryTab } from './ProductList/CategoryTab';
import { SearchProductSection } from './productPage/SearchProductSection';
import ProductWidget from './productPage/ProductWidget';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCaretDown,faCaretUp,faList,faTableCells } from '@fortawesome/free-solid-svg-icons'
function useQuery(){
    return new URLSearchParams(useLocation().search);
}

function ProductList({cartCount,setCartCount,SetCartMsg}) {

    const { products,isLoading } = useSelector(( state )=> state.products);    
    const [columnset,setColumnSet] = useState('md:basis-[19%] basis-[45%]');
    const [items,setItems] = useState(products);
    const query = useQuery();
   
    useEffect(()=>{
        setItems(products);
      
    },[products])

console.log(products);
    if(isLoading){ return 'Loading'; }

    
    // if(!isLoading && !categories.length){ return 'No Category'; }
 


  return (
    <>
        <section className='cat_page my-9'>
            <div className='container mx-auto'>

                <div className='cat_inner_content flex md:flex-row flex-col gap-5'>

                   <CategoryTab />
                   <SearchProductSection columnset={columnset} setColumnSet={setColumnSet} items={items}  setCartCount={setCartCount} cartCount={cartCount} SetCartMsg={SetCartMsg} />
                   

                </div>

            </div>
        </section>
    </>
  )
}

export default ProductList