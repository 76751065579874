import {AUTH,LOGOUT }  from '../constants/actionTypes';

const authReducer = (state={ isAuthLoading:true,authData:null, authError:null },action) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem('profile',JSON.stringify({ ...action?.data }))
            return {...state, authData: action?.data,authError:null};
        case LOGOUT:
            const cartData = localStorage.getItem('cart'); // Store cart data temporarily
            localStorage.clear(); // Clear all data
            if (cartData) {
                localStorage.setItem('cart', cartData); // Restore cart data
            }
            return {...state, authData: null };
        default:
            return state;
    }
}

export default authReducer;