import { usePopup } from "../context/popup";

export default function PopupFlyers({ imageUrl }) {
    const { isOpen, setIsOpen } = usePopup();


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center h-screen bg-black bg-opacity-70 z-10">
      <div className="relative">
        <button
          className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md"
          onClick={() => setIsOpen(false)}
        >
          ✕
        </button>
        <img src={imageUrl} alt="Popup" className="max-w-full max-h-screen" />
      </div>
    </div>
  );
}
